import {
  CompletionTypeSlug,
  TyreAdditionalStateValue,
  TyreLogInDeposit,
  TyreLogOnCar,
  TyreLogWithMeta,
  TyrePositionSlug,
  TyreResource,
} from '@data/tyres/tyres.model';
import { ServiceResource } from '@data/services/services.interface';
import { StatusResource } from '@data/status/status.interfaces';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { DispositionOriginValue, DispositionStatusName } from '@data/dispositions/dispositions.model';
import { Id } from '@core/http/crud-model';
import { SuborderResource } from '@data/orders/orders.model';

export enum TRANSFER_TO_DEPOSIT_ACTIONS {
  DISPOSAL,
  ACCEPTANCE,
  PICKED_BY_DRIVER,
}

export enum TRANSFER_TO_CAR_ACTIONS {
  SPEND,
}

export interface TyreToDepositTransfer {
  tyre: TyreLogOnCar;
  // action: TRANSFER_TO_DEPOSIT_ACTIONS;
}

export interface TyreToCarTransfer {
  tyre: TyreLogInDeposit;
  action: TRANSFER_TO_CAR_ACTIONS;
}

export interface Service {
  service: ServiceResource;
  meta: ServiceMeta;
}

export interface ServiceGroup {
  service: ServiceResource;
  meta: ServiceGroupMeta;
}

export interface ServiceMeta {
  executionDate: string;
  authorizationRequired: boolean;
  authorizationStatus: StatusResource;
  isNew: boolean;
  tirePosition?: TyrePositionSlug;
  damage?: string;
  remarks?: string;
  tire?: TyreLogWithMeta;
  tireRaw?: TyreResource;
  isDecremented?: boolean;
  order?: SuborderResource;
  deferred?: boolean;
  price_fleet?: number;
  price_workshop?: number;
  producer?: string;
  catalog_number?: string;
  part_type?: DispositionItemPartTypeValue;
  deactivates_tyre_quantity_validation?: boolean;
  dispositionItemId?: Id;
  quantity?: number;
  suborder_id?: number;
}

export interface ServiceGroupMeta extends ServiceMeta {
  quantity: number;
}

export interface CarAndWheels {
  data: CarAndWheelsData;
  meta: CarAndWheelsMeta;
}

export interface CarAndWheelsData {
  rim: RimTypeValue;
  spare: string;
  tpms: boolean;
}

export interface CarAndWheelsMeta {
  valid: boolean;
  pristine: boolean;
}

export interface CarAndDriver {
  data: CarAndDriverData;
  meta: CarAndDriverMeta;
}

export interface CarAndDriverData {
  driver_name: string;
  driver_phone: string;
  mileage: number;
  tyre_segment: string;
  tyre_brand: string;
}

export interface CarAndDriverMeta {
  valid: boolean;
}

export interface DispositionPostRequest {
  fleet_id: string;
  vehicle_id: string;
  driver_name: string;
  driver_phone: string;
  mileage: string;
  tpms: LaravelBoolean;
  rim_type: RimTypeValue;
  spare_wheel: string;
  tyres: DispositionStoreRequestTyre[];
  services: DispositionStoreRequestService[];
  invoice_id?: string;
  cost_group_id?: string;
  service_comment: string;
  status: DispositionStatusName;
}

export interface DispositionDraftPostRequest {
  fleet_id: string;
  workshop_id: number;
  status: DispositionStatusName;
  vehicle_id: Id;
  tyres: DispositionStoreRequestTyre[];
  services: any; // TODO
  origin: DispositionOriginValue;
}

export interface DispositionStoreRequestTyre {
  id: string;
  tread_depth?: string;
  tyre_position: string;
  description: string;
  tyre_state: TyreStateValue;
  tyre_additional_states: TyreAdditionalStateValue[];
  force_update_tyre_additional_states: boolean;
  deposit_code: string;
  completion_type: CompletionTypeSlug;
  rim_type?: RimTypeValue;
  rim_to_remove?: boolean;
}

export interface DispositionStoreRequestService {
  id: number;
  planned_at: string;
  quantity: number;
  auth: boolean;
  is_deferred?: boolean;
  price_fleet?: number;
  price_workshop?: number;
  producer?: string;
  part_type?: DispositionItemPartTypeValue;
  description?: string;
  catalog_number?: string;
}

export type TyreStateValue = 'deposit' | 'vehicle' | 'own_pick' | 'disposed_of' | 'relocation' | 'deleted';

export type RimTypeValue = 'aluminum' | 'steel' | 'no';

export class RimType {
  static ALUMINIUM: RimTypeValue = 'aluminum';
  static STEEL: RimTypeValue = 'steel';
  static NO: RimTypeValue = 'no'; // Info: Virtual

  static getTranslateKey(value: RimTypeValue): string {
    return 'rim-type-with-rim.' + value;
  }
}

export type SpareWheelType = 'spare_wheel' | 'full_size';

export class DriverVerificationData {
  disposition_id: number;
  phone_number: string;
}

export class DriverCodeSmsVerification {
  disposition_id: number;
  phone_number: string;
  code: string;
}

export class TyreState {
  public static DEPOSIT: TyreStateValue = 'deposit';
  public static VEHICLE: TyreStateValue = 'vehicle';
  public static OWN_PICK: TyreStateValue = 'own_pick';
  public static DISPOSED_OF: TyreStateValue = 'disposed_of';
  public static RELOCATION: TyreStateValue = 'relocation';
  public static DELETED: TyreStateValue = 'deleted';

  private static ICONS = new Map<TyreStateValue, string>([
    [TyreState.DEPOSIT, 'tyre_state_deposit_m'],
    [TyreState.VEHICLE, 'tyre_state_vehicle_m'],
    [TyreState.OWN_PICK, 'tyre_state_driver_m'],
    [TyreState.DISPOSED_OF, 'tyre_state_disposed_of_m'],
    [TyreState.RELOCATION, 'tyre_state_relocation_m'],
  ]);

  static statesWithoutDepositCode(): TyreStateValue[] {
    return [TyreState.OWN_PICK, TyreState.DISPOSED_OF];
  }

  static hasDepositCode(state: TyreStateValue): boolean {
    return !TyreState.statesWithoutDepositCode().includes(state);
  }

  static nontransferableToCar(): TyreStateValue[] {
    return [TyreState.OWN_PICK, TyreState.DISPOSED_OF];
  }

  static deposit(): TyreState[] {
    return [TyreState.DEPOSIT];
  }

  static car(): TyreState[] {
    return [TyreState.VEHICLE];
  }

  static draft(): TyreState[] {
    return [...TyreState.car(), ...TyreState.deposit()];
  }

  static draftDeposit(): TyreState[] {
    return [TyreState.DEPOSIT, TyreState.DISPOSED_OF, TyreState.OWN_PICK];
  }

  static draftCar(): TyreState[] {
    return [TyreState.VEHICLE];
  }

  static getTranslateKey(value: TyreStateValue): string {
    return 'tyre-state.' + value;
  }

  static getIcon(value: TyreStateValue): string {
    return TyreState.ICONS.get(value);
  }
}

export interface DispositionActionsAddSaleRequest {
  order_items: {
    product_tyre_id: Id;
    quantity: number;
    dot: string;
    term: string;
    price: number;
  }[];
}

export interface DispositionAddZamPostRequest {
  disposition_item_id: Id;
  product_tyres: Array<{
    product_tyre_id: number;
    quantity: number;
  }>;
}

export type DispositionItemPartTypeValue = 'original' | 'substitute';

export class DispositionItemPartType {
  static ORIGINAL: DispositionItemPartTypeValue = 'original';
  static SUBSTITUTE: DispositionItemPartTypeValue = 'substitute';
}
