import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddAdditionalStatus,
  AddDescriptionToTyre,
  AddRequestRemarks,
  AddService,
  AddServices,
  AddStatus,
  AddToCar,
  AddToDeposit,
  ChangeTirePosition,
  ClearDepositAdditionalStatuses,
  ClearDispositionAdditionalStatuses,
  ClearHighlightedLastTransferredTyresInDeposit,
  DispositionAddEditStateModel,
  HighlightLastTransferredTyresInDeposit,
  HighlightLastTransferredTyresOnCar,
  MoveToTrash,
  RemoveAllServices,
  RemoveFromCar,
  RemoveFromDeposit,
  RemoveService,
  ResetCarAndDriverValues,
  ResetRequestAddEditState,
  ResetTyreHighlight,
  SaveStateSnapshot,
  SetCarAndDriverValues,
  SetCarAndWheelsValues,
  SetCompletionType,
  SetDepositCode,
  SetDisposition,
  SetEditingTire,
  SetFleet,
  SetMinimumTreadDepth,
  SetPristine,
  SetRimToRemove,
  SetRimType,
  SetRimTypeToTyre,
  SetServiceAuthorizationStatus,
  SetServiceExecutionDate,
  SetServiceFleetPrice,
  SetServicesAuthorizationStatus,
  SetServiceWorkshopPrice,
  SetSteps,
  SetTyreHighlight,
  SetTyreInDeposit,
  SetTyreOnCar,
  SetTyreTread,
  SetupTyreStates,
  SetVehicle,
  SetWorkshopId,
  StartProcessing,
  StopProcessing,
  Undo,
} from '@data/disposition-add-edit/disposition-add-edit.actions';
import {
  TyreAdditionalState,
  TyreAdditionalStateValue,
  TyreLogInDeposit,
  TyreLogOnCar,
  TyreLogWithMeta,
  TyrePosition,
  TyrePositionSlug,
  TyreResource,
  TyreStateResource,
} from '@data/tyres/tyres.model';
import { compose, patch, updateItem } from '@ngxs/store/operators';
import {
  RimTypeValue,
  Service,
  ServiceGroup,
  TyreStateValue,
} from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DateTime } from 'luxon';
import {
  createServiceGroup,
  groupBy,
  isNotProduct,
  isNotSale,
  isProduct,
  isSale,
  MAX_TREAD_DEPTH,
} from '@data/disposition-add-edit/disposition-add-edit.model';
import { TyresService } from '@data/tyres/tyres.service';
import { map } from 'rxjs/operators';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { ServiceMatchesService } from '@data/disposition-add-edit/service-matches/service-matches.service';
import { DispositionResource } from '@data/dispositions/dispositions.model';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { FleetResource } from '@data/fleets/fleets.model';

const DEFAULTS: any = {
  tyres_on_car: [],
  tyres_in_deposit: [],
  minimum_thread_depth: {
    summer: 0,
    winter: 0,
    yearly: 0,
  },
  services: [],
  processing: false,
  pristine: true,
  remarks: '',
  // driver: {
  //   name: null,
  //   phone_number: null,
  // },
  // mileage: null,
  // rim: null,
  // spare: null,
  // tpms: null,
  car_and_driver: {
    data: {
      driver_name: null,
      driver_phone: null,
      mileage: null,
      tyre_segment: 'Low', // TODO: hardcoded
      tyre_brand: 'Michelin', // TODO: hardcoded
    },
    meta: {
      valid: null,
    },
  },
  car_and_wheels: {
    data: {
      vehicle_type: 'Osobowy', // TODO: hardcoded
      number_of_wheels: null,
      rim: null,
      spare: null,
      tpms: null,
    },
    meta: {
      valid: null,
      pristine: true,
    },
  },
  workshopId: null,
  logKey: 'current_log',
  fleet: null,
  trash: [],
};

@State<DispositionAddEditStateModel>({
  name: 'dispositionAddEdit',
  defaults: {
    ...DEFAULTS,
    ...{
      steps: [],
      vehicle: null,
      fill_data_overlay: {
        visible: false,
        dont_show_in_future: false,
        checked: false,
      },
    },
    _snapshot: null,
    states: [],
  },
})
@Injectable()
export class DispositionAddEditState {
  @Selector()
  static vehicle(state: DispositionAddEditStateModel): VehicleResource {
    return state.vehicle;
  }

  @Selector()
  static tyresOnCar(state: DispositionAddEditStateModel): TyreLogOnCar[] {
    return state?.tyres_on_car || null;
  }

  @Selector()
  static tyresOnCarSpare(state: DispositionAddEditStateModel): TyreLogOnCar {
    return state?.tyres_on_car.find((tyre: TyreLogOnCar) => tyre.meta.position === TyrePosition.SPARE);
  }

  @Selector()
  static tyresInDeposit(state: DispositionAddEditStateModel): TyreLogInDeposit[] {
    return state?.tyres_in_deposit || null;
  }

  @Selector()
  static tyresOnCarAndInDeposit(state: DispositionAddEditStateModel): TyreLogOnCar[] {
    const tyres = [];
    if (state?.tyres_on_car) {
      tyres.push(...state.tyres_on_car);
    }
    if (state?.tyres_in_deposit) {
      tyres.push(...state.tyres_in_deposit);
    }
    return tyres || null;
  }

  @Selector()
  static minimumTreadDepth(
    state: DispositionAddEditStateModel
  ): {
    summer: number;
    winter: number;
    yearly: number;
  } {
    return state?.minimum_thread_depth || null;
  }

  @Selector()
  static tyresForDisposal(state: DispositionAddEditStateModel): TyreResource[] {
    const forDisposalTyres: TyreResource[] = [];
    Object.keys(state.tyres_on_car).map((key: string) => {
      if (!state.tyres_on_car[key].hasRequiredDepth) {
        forDisposalTyres.push(state.tyres_on_car[key]);
      }
    });
    return forDisposalTyres;
  }

  @Selector()
  static steps(state: DispositionAddEditStateModel): string[] {
    return state.steps;
  }

  @Selector()
  static services(state: DispositionAddEditStateModel): Service[] {
    return state.services;
  }

  @Selector()
  static servicesAndProductsGroupedById(state: DispositionAddEditStateModel): ServiceGroup[] {
    const servicesWithoutSale = state.services.filter(isNotSale);
    const saleServices = state.services.filter(isSale);
    const servicesGroupWithoutSale = groupBy(servicesWithoutSale, (service: Service) => service.service.id);

    const orderWithReservationSales = saleServices.filter((service) => !!service.meta.suborder_id);
    const orderWithReservationSalesGroup = groupBy(
      orderWithReservationSales,
      (service: Service) => service.meta.suborder_id
    ).map((service) => {
      return {
        service: service.service,
        meta: {
          ...service.meta,
          quantity: 1,
        },
      };
    });
    const otherSales = saleServices.filter((service) => !service.meta.suborder_id);
    const otherSalesGroup = otherSales.map((service: Service) => {
      return createServiceGroup(service, 1, service.meta.isNew);
    });
    return [...servicesGroupWithoutSale, ...otherSalesGroup, ...orderWithReservationSalesGroup];
  }

  @Selector()
  static productsGroupedById(state: DispositionAddEditStateModel): ServiceGroup[] {
    const products = state.services.filter(isProduct);
    return groupBy(products, (service: Service) => service.service.id);
  }

  @Selector()
  static servicesGroupedById(state: DispositionAddEditStateModel): ServiceGroup[] {
    const services = state.services.filter(isNotProduct);
    return groupBy(services, (service: Service) => service.service.id);
  }

  @Selector()
  static treadsCorrectlyFilled(state: DispositionAddEditStateModel): boolean {
    return state.tyres_on_car.filter((t) => t.meta.touched).length === state.tyres_on_car.length; // TODO: if transfer from deposit first length is 8 not 4 and this condition in not correct
  }

  @Selector()
  static tyreOnCarLeftFront(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.LEFT_FRONT);

    return tyre || null;
  }

  @Selector()
  static tyreOnCarRightFront(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.RIGHT_FRONT);

    return tyre || null;
  }

  @Selector()
  static tyreOnCarLeftRear(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.LEFT_REAR);

    return tyre || null;
  }

  @Selector()
  static tyreOnCarRightRear(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.RIGHT_REAR);

    return tyre || null;
  }

  @Selector()
  static tyreOnCarLeftRear2(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.LEFT_REAR_2);

    return tyre || null;
  }

  @Selector()
  static tyreOnCarRightRear2(state: DispositionAddEditStateModel): TyreLogWithMeta {
    const [tyre] = state.tyres_on_car.filter((tyre) => tyre.meta.position === TyrePosition.RIGHT_REAR_2);

    return tyre || null;
  }

  @Selector()
  static pristine(state: DispositionAddEditStateModel): boolean {
    return state.pristine;
  }

  @Selector()
  static rim(state: DispositionAddEditStateModel): RimTypeValue {
    return state.car_and_wheels.data.rim;
  }

  @Selector()
  static spare(state: DispositionAddEditStateModel): string {
    return state.car_and_wheels.data.spare;
  }

  @Selector()
  static tpms(state: DispositionAddEditStateModel): boolean {
    return state.car_and_wheels.data.tpms;
  }

  @Selector()
  static driverName(state: DispositionAddEditStateModel): string {
    return state.car_and_driver?.data?.driver_name || '';
  }

  @Selector()
  static driverPhone(state: DispositionAddEditStateModel): string {
    return state.car_and_driver?.data?.driver_phone || '';
  }

  @Selector()
  static mileage(state: DispositionAddEditStateModel): number {
    return state.car_and_driver?.data?.mileage || null;
  }

  @Selector()
  static carAndDriverValid(state: DispositionAddEditStateModel): boolean {
    return !!state.car_and_driver?.meta?.valid;
  }

  @Selector()
  static carAndWheelsValid(state: DispositionAddEditStateModel): boolean {
    return !!state.car_and_wheels?.meta?.valid;
  }

  @Selector()
  static carAndWheelsPristine(state: DispositionAddEditStateModel): boolean {
    return !!state.car_and_wheels?.meta?.pristine;
  }

  @Selector()
  static remarks(state: DispositionAddEditStateModel): string {
    return state.remarks || '';
  }

  @Selector()
  static threadsHaveMinimumValues(state: DispositionAddEditStateModel): boolean {
    let err = 0;
    state.tyres_on_car.forEach((t: TyreLogOnCar) => {
      err += +(+t.data?.tread_depth > MAX_TREAD_DEPTH);
    });

    return !err;
  }

  @Selector()
  static serviceComment(state: DispositionAddEditStateModel): string {
    return state.remarks || '';
  }

  @Selector()
  static states(state: DispositionAddEditStateModel): TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[] {
    return state.states;
  }

  @Selector()
  static workshopId(state: DispositionAddEditStateModel): number {
    return state.workshopId;
  }

  @Selector()
  static logKey(state: DispositionAddEditStateModel): string {
    return state.logKey;
  }

  @Selector()
  static fleet(state: DispositionAddEditStateModel): FleetResource {
    return state.fleet;
  }

  @Selector()
  static disposition(state: DispositionAddEditStateModel): DispositionResource {
    return state.disposition;
  }

  @Action(SetVehicle)
  setVehicle(ctx: StateContext<DispositionAddEditStateModel>, action: SetVehicle): void {
    ctx.patchState({
      vehicle: action.vehicle,
    });
  }

  @Action(SetTyreOnCar)
  setTyreOnCar(ctx: StateContext<DispositionAddEditStateModel>, action: SetTyreOnCar): void {
    const season = action.tyre.tyre?.tyre_season?.id;
    ctx.patchState({
      tyres_on_car: [
        ...ctx.getState().tyres_on_car,
        ...[
          {
            data: action.tyre,
            meta: {
              position: action.position,
              season: season,
              requiredDepth: parseFloat(ctx.getState().minimum_thread_depth[season]),
              // status: action.tyre?.status,
              editing: false,
              origin: 'car' as 'car' | 'deposit',
              touched: action.touched,
              maxDepth: this.getMaxTreadDepth(ctx.getState(), action.tyre.tyre),
              highlight: false,
              description: action.tyre?.description,
              deletable: action.deletable,
            },
          },
        ],
      ],
    });
  }

  @Action(SetTyreInDeposit)
  setTyreInDeposit(ctx: StateContext<DispositionAddEditStateModel>, action: SetTyreInDeposit): void {
    const season = action.tyre.tyre?.tyre_season?.id;
    ctx.patchState({
      tyres_in_deposit: [
        ...ctx.getState().tyres_in_deposit,
        ...[
          {
            data: action.tyre,
            meta: {
              position: action.position,
              season: season,
              requiredDepth: parseFloat(ctx.getState().minimum_thread_depth[season]),
              // status: action.tyre?.status,
              editing: false,
              origin: 'deposit' as 'car' | 'deposit',
              maxDepth: this.getMaxTreadDepth(ctx.getState(), action.tyre.tyre),
              touched: action.touched,
              highlight: false,
              description: action.tyre?.description,
              deletable: action.deletable,
            },
          },
        ],
      ],
    });
  }

  // @Action(SetTyresInDeposit)
  // setTyresInDeposit(ctx: StateContext<RequestAddEditStateModel>, action: SetTyresInDeposit) {
  //   const keys = Object.keys(action.tyres);
  //   const [status] = ctx.getState().states.filter((s) => s.value === 'deposit');
  //   const tyres = keys.map<TyreOnCar>((key) => {
  //     const season = action.tyres[key]?.tyre_season?.id;
  //     return {
  //       data: action.tyres[key],
  //       meta: {
  //         position: key as 'lp' | 'lt' | 'pp' | 'pt' | 'zap',
  //         season: season,
  //         requiredDepth: parseFloat(ctx.getState().minimum_thread_depth[season]),
  //         status: status,
  //         editing: false,
  //         origin: 'deposit',
  //       },
  //     };
  //   });
  //   ctx.patchState({
  //     tyres_in_deposit: tyres,
  //   });
  // }

  @Action(SetTyreTread)
  setTyreTread(ctx: StateContext<DispositionAddEditStateModel>, action: SetTyreTread): void {
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tread_depth: action.depth.toString(),
              },
              meta: {
                ...item.meta,
                hasRequiredDepth: action.depth > item.meta.requiredDepth,
                touched: true,
              },
            };
          }
        ),
        tyres_in_deposit: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tread_depth: action.depth.toString(),
              },
              meta: {
                ...item.meta,
                hasRequiredDepth: action.depth > item.meta.requiredDepth,
                touched: true,
              },
            };
          }
        ),
      })
    );
  }

  @Action(SetTyreHighlight)
  setTyreHighlight(ctx: StateContext<DispositionAddEditStateModel>, action: SetTyreTread): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
        },
        meta: {
          ...item.meta,
          highlight: true,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(ResetTyreHighlight)
  resetTyreHighlight(ctx: StateContext<DispositionAddEditStateModel>, action: SetTyreTread): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
        },
        meta: {
          ...item.meta,
          highlight: false,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(SetMinimumTreadDepth)
  setMinimumTreadDepth(ctx: StateContext<DispositionAddEditStateModel>, action: SetMinimumTreadDepth): void {
    ctx.setState({
      ...ctx.getState(),
      minimum_thread_depth: {
        ...ctx.getState().minimum_thread_depth,
        [action.season]: action.depth,
      },
    });
  }

  @Action(SetSteps)
  setSteps(ctx: StateContext<DispositionAddEditStateModel>, action: SetSteps): void {
    ctx.patchState({
      steps: action.steps,
    });
  }

  @Action(AddService)
  addService(ctx: StateContext<DispositionAddEditStateModel>, action: AddService): void {
    ctx.patchState({
      services: [
        ...ctx.getState().services.map((s) => ({
          ...s,
          meta: {
            ...s.meta,
            isNew: false,
          },
        })),
        {
          service: action.service,
          meta: {
            ...{
              executionDate: DateTime.now().toFormat('yyyy-LL-dd'),
              authorizationRequired: true,
              authorizationStatus: null,
              isNew: true,
            },
            ...action.partialMeta,
          },
        },
      ],
    });
  }

  @Action(AddServices)
  addServices(ctx: StateContext<DispositionAddEditStateModel>, action: AddServices): void {
    const services = action.services.map<Service>((service: Service) => {
      return {
        service: service.service,
        meta: {
          ...{
            executionDate: DateTime.now().toFormat('yyyy-LL-dd'),
            authorizationRequired: true,
            authorizationStatus: null,
            isNew: true,
          },
          ...service.meta,
        },
      };
    });

    ctx.patchState({
      services: [
        ...ctx.getState().services.map((s) => ({
          ...s,
          meta: {
            ...s.meta,
            isNew: false,
          },
        })),
        ...services,
      ],
    });
  }

  @Action(RemoveService)
  removeService(ctx: StateContext<DispositionAddEditStateModel>, action: RemoveService): void {
    let filtered: Service[] = [];
    if (!action.count) {
      filtered = ctx.getState().services.filter((s) => s.service.id !== action.service.id);
    } else {
      let counter = 0;
      filtered = ctx.getState().services.flatMap((s) => {
        if (s.service.id !== action.service.id) {
          return s;
        } else {
          if (counter < action.count) {
            counter++;
            return [];
          }
          counter++;
          return {
            service: s.service,
            meta: {
              ...s.meta,
              isDecremented: true,
            },
          };
        }
      });
    }

    ctx.patchState({
      services: filtered,
    });
  }

  @Action(RemoveAllServices)
  removeAllServices(ctx: StateContext<DispositionAddEditStateModel>, action: RemoveAllServices): void {
    ctx.patchState({
      services: [],
    });
  }

  @Action(SetServiceExecutionDate)
  setServiceExecutionDate(ctx: StateContext<DispositionAddEditStateModel>, action: SetServiceExecutionDate): void {
    ctx.setState(
      patch({
        services: updateItem(
          (service: Service) =>
            service.service.code === action.service.code && service.meta.executionDate !== action.value,
          (item) => {
            return {
              service: {
                ...item.service,
              },
              meta: {
                ...item.meta,
                executionDate: action.value,
                deferred: action.deferred,
              },
            };
          }
        ),
      })
    );
  }

  @Action(SetServiceWorkshopPrice)
  setServiceWorkshopPrice(ctx: StateContext<DispositionAddEditStateModel>, action: SetServiceWorkshopPrice): void {
    const newServices = ctx.getState().services.map((s) => {
      if (s.service.code === action.service.code) {
        return {
          service: s.service,
          meta: {
            ...s.meta,
            price_workshop: action.price,
          },
        };
      } else {
        return s;
      }
    });

    ctx.patchState({
      services: newServices,
    });
  }

  @Action(SetServiceFleetPrice)
  setServiceFleetPrice(ctx: StateContext<DispositionAddEditStateModel>, action: SetServiceFleetPrice): void {
    const newServices = ctx.getState().services.map((s) => {
      if (s.service.code === action.service.code) {
        return {
          service: s.service,
          meta: {
            ...s.meta,
            price_fleet: action.price,
          },
        };
      } else {
        return s;
      }
    });

    ctx.patchState({
      services: newServices,
    });
  }

  @Action(AddToDeposit)
  addToDeposit(ctx: StateContext<DispositionAddEditStateModel>, action: AddToDeposit): void {
    ctx.patchState({
      tyres_in_deposit: [...ctx.getState().tyres_in_deposit, action.tyre],
    });
  }

  @Action(AddToCar)
  addToCar(ctx: StateContext<DispositionAddEditStateModel>, action: AddToCar): void {
    const tyre = {
      data: {
        ...action.tyre.data,
        deposit_code: '',
      },
      meta: action.tyre.meta,
    };
    ctx.patchState({
      tyres_on_car: [...ctx.getState().tyres_on_car, tyre],
    });
  }

  @Action(RemoveFromCar)
  removeFromCar(ctx: StateContext<DispositionAddEditStateModel>, action: RemoveFromCar): void {
    const filtered = ctx.getState().tyres_on_car.filter((t) => t.data?.tyre?.id !== action.tyre.data?.tyre?.id);

    ctx.patchState({
      tyres_on_car: filtered,
    });
  }

  @Action(RemoveFromDeposit)
  removeFromDeposit(ctx: StateContext<DispositionAddEditStateModel>, action: RemoveFromDeposit): void {
    const filtered = ctx.getState().tyres_in_deposit.filter((t) => t.data?.tyre?.id !== action.tyre.data?.tyre?.id);

    ctx.patchState({
      tyres_in_deposit: filtered,
    });
  }

  @Action(MoveToTrash)
  moveToTrash(ctx: StateContext<DispositionAddEditStateModel>, action: MoveToTrash): void {
    ctx.patchState({
      trash: [...ctx.getState().trash, action.tyre],
    });
  }

  @Action(AddStatus)
  addStatus(ctx: StateContext<DispositionAddEditStateModel>, action: AddStatus): void {
    const status: TyreStateResource<TyreStateValue> = ctx
      .getState()
      .states.find((s) => s.value === action.status) as TyreStateResource<TyreStateValue>;
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_state: status,
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
        tyres_in_deposit: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_state: status,
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
      })
    );
  }

  @Action(AddAdditionalStatus)
  addAdditionalStatus(ctx: StateContext<DispositionAddEditStateModel>, action: AddAdditionalStatus): void {
    const condition = (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      const withoutDuplicates = [...item.data.tyre_additional_states, action.status].filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
      );

      return {
        data: {
          ...item.data,
          tyre_additional_states: withoutDuplicates,
        },
        meta: {
          ...item.meta,
        },
      };
    };

    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(ClearDispositionAdditionalStatuses)
  clearDispositionAdditionalStatuses(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: ClearDispositionAdditionalStatuses
  ): void {
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_additional_states: item.data.tyre_additional_states.filter(
                  (s: TyreStateResource<TyreAdditionalStateValue>) =>
                    !TyreAdditionalState.disposition().includes(s.value)
                ),
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
        tyres_in_deposit: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_additional_states: item.data.tyre_additional_states.filter(
                  (s: TyreStateResource<TyreAdditionalStateValue>) =>
                    !TyreAdditionalState.disposition().includes(s.value)
                ),
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
      })
    );
  }

  @Action(ClearDepositAdditionalStatuses)
  clearDepositAdditionalStatuses(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: ClearDepositAdditionalStatuses
  ): void {
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_additional_states: item.data.tyre_additional_states.filter(
                  (s: TyreStateResource<TyreAdditionalStateValue>) =>
                    !TyreAdditionalState.depositPayment().includes(s.value)
                ),
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
        tyres_in_deposit: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                tyre_additional_states: item.data.tyre_additional_states.filter(
                  (s: TyreStateResource<TyreAdditionalStateValue>) =>
                    !TyreAdditionalState.depositPayment().includes(s.value)
                ),
              },
              meta: {
                ...item.meta,
              },
            };
          }
        ),
      })
    );
  }

  @Action(StartProcessing)
  startProcessing(ctx: StateContext<DispositionAddEditStateModel>, action: StartProcessing): void {
    ctx.patchState({
      processing: true,
    });
  }

  @Action(StopProcessing)
  stopProcessing(ctx: StateContext<DispositionAddEditStateModel>, action: StopProcessing): void {
    ctx.patchState({
      processing: false,
    });
  }

  @Action(SetEditingTire)
  setEditingTire(ctx: StateContext<DispositionAddEditStateModel>, action: SetEditingTire): void {
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => true,
          (item) => {
            return {
              data: {
                ...item.data,
              },
              meta: {
                ...item.meta,
                editing: action.tyre.data?.tyre?.id === item.data?.tyre?.id,
              },
            };
          }
        ),
      })
    );
  }

  @Action(SetPristine)
  setPristine(ctx: StateContext<DispositionAddEditStateModel>, action: SetPristine): void {
    ctx.patchState({
      pristine: action.pristine,
    });
  }

  @Action(ResetRequestAddEditState)
  resetRequestAddEditState(ctx: StateContext<DispositionAddEditStateModel>, action: ResetRequestAddEditState): void {
    ctx.setState({
      ...DEFAULTS,
      steps: ctx.getState().steps,
      vehicle: ctx.getState().vehicle,
      states: ctx.getState().states,
    });
  }

  //
  // @Action(SetDriverName)
  // setDriverName(ctx: StateContext<RequestAddEditStateModel>, action: SetDriverName) {
  //   ctx.setState(
  //     patch({
  //       vehicle: patch({
  //         driver: patch({
  //           name: action.name,
  //         }),
  //       }),
  //     })
  //   );
  // }
  //
  // @Action(SetDriverPhone)
  // setDriverPhone(ctx: StateContext<RequestAddEditStateModel>, action: SetDriverPhone) {
  //   ctx.setState(
  //     patch({
  //       vehicle: patch({
  //         driver: patch({
  //           phone_number: action.phone,
  //         }),
  //       }),
  //     })
  //   );
  // }

  // @Action(SetMileage)
  // setMileage(ctx: StateContext<RequestAddEditStateModel>, action: SetMileage) {
  //   ctx.patchState({
  //     mileage: action.mileage,
  //   });
  // }
  //
  // @Action(SetRim)
  // setRim(ctx: StateContext<RequestAddEditStateModel>, action: SetRim) {
  //   ctx.patchState({
  //     rim: action.rim,
  //   });
  // }
  //
  // @Action(SetSpare)
  // setSpare(ctx: StateContext<RequestAddEditStateModel>, action: SetSpare) {
  //   ctx.patchState({
  //     spare: action.spare,
  //   });
  // }
  //
  // @Action(SetTpms)
  // setTpms(ctx: StateContext<RequestAddEditStateModel>, action: SetTpms) {
  //   ctx.patchState({
  //     tpms: action.tpms,
  //   });
  // }

  @Action(SetCarAndWheelsValues)
  setCarAndWheelsValues(ctx: StateContext<DispositionAddEditStateModel>, action: SetCarAndWheelsValues): void {
    ctx.setState(
      patch({
        car_and_wheels: patch({
          data: action.values,
          meta: patch({
            valid: action.valid,
            pristine: false,
          }),
        }),
      })
    );
  }

  @Action(SetCarAndDriverValues)
  setCarAndDriverValues(ctx: StateContext<DispositionAddEditStateModel>, action: SetCarAndDriverValues): void {
    ctx.setState(
      patch({
        car_and_driver: patch({
          data: action.values,
          meta: patch({
            valid: action.valid,
          }),
        }),
      })
    );
  }

  @Action(ResetCarAndDriverValues)
  resetCarAndDriverValues(ctx: StateContext<DispositionAddEditStateModel>, action: ResetCarAndDriverValues): void {
    ctx.patchState({
      car_and_driver: DEFAULTS.car_and_driver,
    });
  }

  @Action(HighlightLastTransferredTyresInDeposit)
  highlightLastTransferredTyresInDeposit(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: HighlightLastTransferredTyresInDeposit
  ): void {
    const ids = action.tyres.map((t) => t.data?.tyre?.id);

    const changed = ctx.getState().tyres_in_deposit.map(
      (_tyreInDeposit: TyreLogInDeposit): TyreLogInDeposit => ({
        data: _tyreInDeposit.data,
        meta: {
          ..._tyreInDeposit.meta,
          ...{ isNew: ids.includes(_tyreInDeposit.data?.tyre?.id) },
        },
      })
    );

    ctx.setState(
      patch({
        tyres_in_deposit: changed,
      })
    );
  }

  @Action(HighlightLastTransferredTyresOnCar)
  highlightLastTransferredTyresOnCar(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: HighlightLastTransferredTyresOnCar
  ): void {
    const ids = action.tyres.map((t) => t.data?.tyre?.id);

    const changed = ctx.getState().tyres_on_car.map(
      (_tyreOnCar: TyreLogOnCar): TyreLogOnCar => ({
        data: _tyreOnCar.data,
        meta: {
          ..._tyreOnCar.meta,
          ...{ isNew: ids.includes(_tyreOnCar.data?.tyre?.id) },
        },
      })
    );

    ctx.setState(
      patch({
        tyres_on_car: changed,
      })
    );
  }

  @Action(ClearHighlightedLastTransferredTyresInDeposit)
  clearHighlightedLastTransferredTyresInDeposit(ctx: StateContext<DispositionAddEditStateModel>): void {
    const tyresInDeposit = ctx.getState().tyres_in_deposit.map(
      (_tyreInDeposit: TyreLogInDeposit): TyreLogInDeposit => ({
        data: _tyreInDeposit.data,
        meta: {
          ..._tyreInDeposit.meta,
          ...{ isNew: false },
        },
      })
    );

    ctx.setState(
      patch({
        tyres_in_deposit: tyresInDeposit,
      })
    );
  }

  @Action(SaveStateSnapshot)
  saveStateSnapshot(ctx: StateContext<DispositionAddEditStateModel>): void {
    ctx.patchState({
      _snapshot: ctx.getState(),
    });
  }

  @Action(Undo)
  undo(ctx: StateContext<DispositionAddEditStateModel>): void {
    if (ctx.getState()._snapshot) {
      ctx.setState(ctx.getState()._snapshot);
    }
  }

  @Action(SetDepositCode)
  setDepositCode(ctx: StateContext<DispositionAddEditStateModel>, action: SetDepositCode): void {
    ctx.setState(
      patch({
        tyres_in_deposit: updateItem(
          (t: TyreLogInDeposit) => t.data?.tyre?.id === action.tyre.data?.tyre?.id,
          (item) => {
            return {
              data: {
                ...item.data,
                deposit_code: action.code,
              },
              meta: item.meta,
            };
          }
        ),
      })
    );
  }

  @Action(AddRequestRemarks)
  addRequestRemarks(ctx: StateContext<DispositionAddEditStateModel>, action: AddRequestRemarks): void {
    ctx.patchState({
      remarks: action.remarks,
    });
  }

  @Action(SetupTyreStates)
  setupStatuses(ctx: StateContext<DispositionAddEditStateModel>, action: SetupTyreStates): void {
    this.tyresResourceService
      .getStates()
      .pipe(map((res: PagedResource<TyreStateResource<TyreStateValue | TyreAdditionalStateValue>>) => res.data))
      .subscribe((states: TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[]) => {
        ctx.patchState({
          states: states,
        });
      });
  }

  @Action(ChangeTirePosition)
  changeTirePosition(ctx: StateContext<DispositionAddEditStateModel>, action: ChangeTirePosition): void {
    ctx.setState(
      patch({
        tyres_on_car: updateItem(
          (tyre: TyreLogOnCar) => tyre.data?.tyre?.id === action.tire.data?.tyre?.id,
          (item) => {
            return {
              data: item.data,
              meta: {
                ...item.meta,
                position: action.position,
                touched: action.touched,
              },
            };
          }
        ),
      })
    );
  }

  @Action(SetServiceAuthorizationStatus)
  setServiceAuthorizationStatus(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: SetServiceAuthorizationStatus
  ): void {
    ctx.setState(
      patch({
        services: updateItem(
          (s: Service) => s.service.id.toString() === action.serviceId.toString(),
          (item) => {
            return {
              service: item.service,
              meta: {
                ...item.meta,
                authorizationStatus: action.authorizationStatus,
              },
            };
          }
        ),
      })
    );
  }

  @Action(SetServicesAuthorizationStatus)
  setServicesAuthorizationStatus(
    ctx: StateContext<DispositionAddEditStateModel>,
    action: SetServicesAuthorizationStatus
  ): void {
    const services = ctx.getState().services.map((service: Service) => {
      if (service.meta.authorizationRequired) {
        return {
          service: service.service,
          meta: {
            ...service.meta,
            authorizationStatus: action.authorizationStatus,
          },
        };
      } else {
        return service;
      }
    });

    ctx.patchState({
      services: services,
    });
  }

  @Action(SetWorkshopId)
  setWorkshopId(ctx: StateContext<DispositionAddEditStateModel>, action: SetWorkshopId): void {
    ctx.patchState({
      workshopId: action.workshopId,
    });
  }

  @Action(SetFleet)
  setFleet(ctx: StateContext<DispositionAddEditStateModel>, action: SetFleet): void {
    ctx.patchState({
      fleet: action.fleet,
    });
  }

  @Action(SetDisposition)
  setDisposition(ctx: StateContext<DispositionAddEditStateModel>, action: SetDisposition): void {
    ctx.patchState({
      disposition: action.disposition,
    });
  }

  @Action(AddDescriptionToTyre)
  addDescriptionToTyre(ctx: StateContext<DispositionAddEditStateModel>, action: AddDescriptionToTyre): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
        },
        meta: {
          ...item.meta,
          description: action.description,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(SetRimTypeToTyre)
  setRimTypeToTyre(ctx: StateContext<DispositionAddEditStateModel>, action: SetRimTypeToTyre): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
          rim_type: action.rimType,
        },
        meta: {
          ...item.meta,
          rimType: action.rimType,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(SetCompletionType)
  setCompletionType(ctx: StateContext<DispositionAddEditStateModel>, action: SetCompletionType): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.tyre.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
          completion_type: action.completionType,
        },
        meta: {
          ...item.meta,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  @Action(SetRimType)
  setRimType(ctx: StateContext<DispositionAddEditStateModel>, action: SetRimType): void {
    ctx.setState(
      patch({
        car_and_wheels: patch({
          data: patch({
            rim: action.rimType,
          }),
        }),
      })
    );
  }

  @Action(SetRimToRemove)
  setRimToRemove(ctx: StateContext<DispositionAddEditStateModel>, action: SetRimToRemove): void {
    const condition = (tyre: TyreLogWithMeta) => tyre.data?.tyre?.id === action.rim.data?.tyre?.id;
    const update = (item: Readonly<TyreLogWithMeta>): TyreLogWithMeta => {
      return {
        data: {
          ...item.data,
          origin_tyre_log_id: action.originTyre?.data?.id,
        },
        meta: {
          ...item.meta,
        },
      };
    };
    ctx.setState(
      patch({
        tyres_on_car: updateItem(condition, update),
        tyres_in_deposit: updateItem(condition, update),
      })
    );
  }

  constructor(private tyresResourceService: TyresService, private serviceMatchesService: ServiceMatchesService) {}

  getMaxTreadDepth(state: DispositionAddEditStateModel, tyre: TyreResource): number {
    const currentTyre = state.vehicle?.current_tyres?.find((t) => t.id?.toString() === tyre?.id?.toString());
    if (currentTyre && currentTyre.current_log && currentTyre.current_log.tread_depth) {
      return parseFloat(currentTyre.current_log.tread_depth);
    } else {
      return MAX_TREAD_DEPTH;
    }
  }

  static tyreInDeposit(position: TyrePositionSlug): (state: DispositionAddEditStateModel) => object {
    return createSelector([DispositionAddEditState], (state: DispositionAddEditStateModel) => {
      const [tyre] = state.tyres_in_deposit.filter((tyre) => tyre.meta.position === position);

      return tyre || null;
    });
  }
}
